.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* .react-slideshow-container .default-nav:last-of-type {
  margin-left: -30px;
  display: none;
}
.react-slideshow-container .default-nav:first-of-type {


} */
 .title {
  display: block;
  font-family: monospace;
  white-space: nowrap;
  /* border-right: 4px solid; */
  width: 100%;
  
  animation: typing 2s steps(12), blink .5s infinite step-end alternate;
  overflow:hidden;
  }
 
/*   
  @keyFrames typing {
   from { width: 0}

  }
  
  @keyFrames blink {
   50% { border-color: transparent}
  } */

  
  .title{
    color: white;
    font-size: 1.25rem;

  }
  :root {
    --body-bg-color: #1a1c1d;
    --text-color: #aaaebc;
    --hr-color: #26292a;
    --red: #e74c3c;
  }
  
  ul {
    list-style: none;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  body {
    margin: 30px 0;
    font: 22px sans-serif;
    background: var(--body-bg-color);
    color: var(--text-color);
  }
  
  hr {
    border-color: var(--hr-color);
    margin: 20px 0;
  }
  
  .menu {
    display: flex;
    justify-content: center;
  }
  
  /* .menu li {
    margin-right: 70px; 
  } */
  
  .menu a {
    position: relative;
    display: block;
    margin:30px;
    padding: 10px;
    font-size: 20px;
  }
  
  .menu a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #07e107, #4cc5fa, #086ce6);
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }
  
  .menu a:hover::before {
    transform: scaleX(1);
  }
  
  
  /* ANIMATIONS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .menu[data-animation="to-left"] a::before {
    transform-origin: right;
  }
  
  .menu[data-animation="center"] a::before {
    transform-origin: center;
  }
  
  .menu[data-animation="bonus"] a::before {
    transform-origin: right;
  }
  
  .menu[data-animation="bonus"] a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.82, 0.94);
  }
  

#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
  font-family: 'Play !important', sans-serif;
}
h1,h2,h3,h4,h5,h6,p,a,.MuiTypography-root, .css-18m2q64-MuiTypography-root,.css-18m2q64-MuiTypography-root, .css-qxac6f-MuiFormLabel-root-MuiInputLabel-root, .title {
  font-family: 'Play', sans-serif !important;
}
body {
  background: #eee;
  font-size: 14px;
  font-family: 'Play', sans-serif;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.react-slideshow-container .default-nav {

  background: rgb(255 255 255 / 0%);

}
.react-slideshow-container .default-nav {
  background: rgb(255 255 255 / 0%);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
/* .react-slideshow-container .default-nav:last-of-type {
  margin-left: -30px;
  display: none;
}
.react-slideshow-container .default-nav:first-of-type {


} */
 .title {
  display: block;
  font-family: monospace;
  white-space: nowrap;
  /* border-right: 4px solid; */
  width: 100%;
  
  -webkit-animation: typing 2s steps(12), blink .5s infinite step-end alternate;
  
          animation: typing 2s steps(12), blink .5s infinite step-end alternate;
  overflow:hidden;
  }
 
/*   
  @keyFrames typing {
   from { width: 0}

  }
  
  @keyFrames blink {
   50% { border-color: transparent}
  } */

  
  .title{
    color: white;
    font-size: 1.25rem;

  }
  :root {
    --body-bg-color: #1a1c1d;
    --text-color: #aaaebc;
    --hr-color: #26292a;
    --red: #e74c3c;
  }
  
  ul {
    list-style: none;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  body {
    margin: 30px 0;
    font: 22px sans-serif;
    background: #1a1c1d;
    background: var(--body-bg-color);
    color: #aaaebc;
    color: var(--text-color);
  }
  
  hr {
    border-color: #26292a;
    border-color: var(--hr-color);
    margin: 20px 0;
  }
  
  .menu {
    display: flex;
    justify-content: center;
  }
  
  /* .menu li {
    margin-right: 70px; 
  } */
  
  .menu a {
    position: relative;
    display: block;
    margin:30px;
    padding: 10px;
    font-size: 20px;
  }
  
  .menu a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #07e107, #4cc5fa, #086ce6);
    z-index: 1;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  }
  
  .menu a:hover::before {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  
  
  /* ANIMATIONS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .menu[data-animation="to-left"] a::before {
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  
  .menu[data-animation="center"] a::before {
    -webkit-transform-origin: center;
            transform-origin: center;
  }
  
  .menu[data-animation="bonus"] a::before {
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  
  .menu[data-animation="bonus"] a:hover::before {
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.82, 0.94);
  }
  


